import { createAsyncThunk } from "@reduxjs/toolkit";
import { DataProvider } from "../../api/dataProvider";
import { message } from "antd";
import {
  IAddPlanToolItem,
  IAdvancedSearchCriteria,
  IPlanMaterialItem,
  IPlanNew,
  IPlanToolItem,
  ISearchCriteria,
} from "../../interfaces/IPlan";
import { setPlanId } from "../slices/PlansSlice";
import exp from "constants";

interface IPlansListData {
  pageNumber?: number;
  pageSize?: number;
  searchValue?: string;
  categoryIds?: string[];
  subCategoryIds?: string[];
}

const defaultPlansListData: IPlansListData = {
  pageNumber: 1,
  pageSize: 200,
  searchValue: "",
  categoryIds: [],
};

export const fetchPlansList = createAsyncThunk(
  "plans/fetchPlansList",
  async (data: IPlansListData, thunkAPI?: any) => {
    const { pageNumber, pageSize, searchValue, categoryIds, subCategoryIds } =
      data;
    try {
      const result = await DataProvider.getList(
        `plans?search=${searchValue}&pageSize=${pageSize}&pageNumber=${pageNumber}&categoryIds=${categoryIds}&subcategoryIds=${subCategoryIds}`
      );

      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const fetchSearchedPlans = createAsyncThunk(
  "plans/fetchSearchedPlans",
  async (value: string, thunkAPI?: any) => {
    try {
      const result = await DataProvider.getList(`dashboard?search=${value}`);

      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const fetchPlanDetails = createAsyncThunk(
  "plans/fetchPlanDetails",
  async (id: string, thunkAPI?: any) => {
    try {
      const result = await DataProvider.getOne(`plans`, { id });

      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const addPlanMaterial = createAsyncThunk(
  "plans/AddPlanMaterial",
  async (data: IPlanMaterialItem, thunkAPI?: any) => {
    try {
      const result = await DataProvider.post("planing-materials", data);
      if (result) {
        message.success("Material added successfully");
      }

      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const addPlanTools = createAsyncThunk(
  "plans/AddPlanTools",
  async (data: IAddPlanToolItem, thunkAPI?: any) => {
    try {
      const result = await DataProvider.post("planing-tools", data);

      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const fetchPlanTools = createAsyncThunk(
  "plans/fetchPlanTools",
  async (data: { searchedValue: string; planId: string }, thunkAPI?: any) => {
    const { searchedValue, planId } = data;
    try {
      const url = `planing-tools?search=${searchedValue}${
        planId ? `&planId=${planId}` : ""
      }`;
      const result = await DataProvider.getList(url);

      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const fetchSelectedPlanTools = createAsyncThunk(
  "plans/fetchSelectedPlanTools",
  async (planId: string, thunkAPI?: any) => {
    try {
      const url = `planing-tools?${planId ? `&planId=${planId}` : ""}`;
      const result = await DataProvider.getList(url);

      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const fetchPlanMaterials = createAsyncThunk(
  "plans/fetchPlanMaterials",
  async (data: { searchedValue: string; planId: string }, thunkAPI?: any) => {
    const { searchedValue, planId } = data;
    try {
      const url = `planing-materials?search=${searchedValue}${
        planId ? `&planId=${planId}` : ""
      }`;
      const result = await DataProvider.getList(url);

      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const fetchSelectedPlanMaterials = createAsyncThunk(
  "plans/fetchSelectedPlanMaterials",
  async (planId: string, thunkAPI?: any) => {
    try {
      const url = `planing-materials?${planId ? `&planId=${planId}` : ""}`;
      const result = await DataProvider.getList(url);

      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const addPlan = createAsyncThunk(
  "plans/addPlan",
  async (data: IPlanNew, thunkAPI?: any) => {
    try {
      const result = await DataProvider.post("plans", data);
      if (result.length > 0) {
        message.success("Plan added successfully");
      }
      thunkAPI.dispatch(
        setPlanId({
          id: result,
          isPreview: true,
        })
      );
      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

const addPlanKeywords = createAsyncThunk(
  "plans/addPlanKeywords",
  async (data: any, thunkAPI?: any) => {
    try {
      const result = await DataProvider.post("plan-keywords", data);

      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const fetchContentDetails = createAsyncThunk(
  "plans/fetchContentDetails",
  async (_, thunkAPI?: any) => {
    try {
      const result = await DataProvider.getList(`plans/content-detail`);

      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const fetchContentOverview = createAsyncThunk(
  "plans/fetchContentOverview",
  async (_, thunkAPI?: any) => {
    try {
      const result = await DataProvider.getList(`plans/content-overview`);

      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const deletePlan = createAsyncThunk(
  "plans/deletePlan",
  async (id: string, thunkAPI?: any) => {
    try {
      const result = await DataProvider.delete(`plans`, { id });
      if (result) {
        message.success("Plan deleted successfully");
        thunkAPI.dispatch(fetchContentDetails());
      }

      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const fetchPlanAdvancedSearch = createAsyncThunk(
  "plans/fetchPlanAdvancedSearch",
  async (data: IAdvancedSearchCriteria, thunkAPI?: any) => {
    const { isFavorite, pageNumber, pageSize, searchCriteria } = data;
    try {
      const result = await DataProvider.post(
        `plans/advance-search?pageNumber=${pageNumber}&pageSize=${pageSize}&isFavorite=${isFavorite}`,
        searchCriteria
      );

      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const updatePlan = createAsyncThunk(
  "plans/updatePlan",
  async (data: { id: string; data: IPlanNew }, thunkAPI?: any) => {
    const combinedData = { id: data.id, ...data.data };
    try {
      const result = await DataProvider.update("plans", combinedData);
      if (result.length > 0) {
        message.success("Plan updated successfully");
      }
      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const updatePlanStatus = createAsyncThunk(
  "plans/updatePlanStatus",
  async (data: any, thunkAPI?: any) => {
    const { planId, reqBody } = data;
    try {
      const result = await DataProvider.update(
        `plans/${planId}/change-status`,
        reqBody
      );

      if (result) {
        message.success("Plan status updated successfully");
        thunkAPI.dispatch(fetchContentDetails());
      }

      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const setFavoritePlan = createAsyncThunk(
  "plans/setFavoritePlan",
  async (data: any, thunkAPI?: any) => {
    const { planId, reqBody } = data;

    try {
      const result = await DataProvider.update(
        `plans/${planId}/favorite?isSetFavorite=${reqBody.isSetFavorite}`
      );

      if (result) {
        message.success(
          `Plan ${
            reqBody?.isSetFavorite ? " added to " : " removed from "
          }  favorite successfully`
        );
        thunkAPI.dispatch(
          fetchPlanAdvancedSearch({
            isFavorite: false,
            pageNumber: 1,
            pageSize: 30,
            searchCriteria: {
              subCategoryId: null,
              difficulty: null,
              maxPlanPrice: null,
              minPlanPrice: null,
              orderType: null,
              projectCost: null,
              rate: null,
              lstRate: null,
            },
          })
        );
        thunkAPI.dispatch(fetchPlansList(defaultPlansListData));
      }

      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const fetchRelatedPlans = createAsyncThunk(
  "plans/fetchRelatedPlans",
  async (
    data: { creatorId: string; exceptId: string | null },
    thunkAPI?: any
  ) => {
    const { creatorId, exceptId } = data;
    try {
      const result = await DataProvider.getList(
        `plans/${creatorId}/more-related`
      );

      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const updatePlanningMaterial = createAsyncThunk(
  "plans/updatePlanningMaterial",
  async (data: { id: string; data: IPlanMaterialItem }, thunkAPI?: any) => {
    const combinedData = { id: data.id, ...data.data };
    try {
      const result = await DataProvider.update(
        "planing-materials",
        combinedData
      );

      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const updatePlanningTool = createAsyncThunk(
  "plans/updatePlanningTool",
  async (data: { id: string; data: IPlanToolItem }, thunkAPI?: any) => {
    try {
      const result = await DataProvider.update("planing-tools", data);

      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);
