import { createAsyncThunk } from "@reduxjs/toolkit";
import { DataProvider } from "../../api/dataProvider";
import { message } from "antd";
import { setConfirmPayment, setShowCartModal } from "../slices/PaymentSlice";
import { fetchPlanAdvancedSearch } from "./PlansActions";

export const fetchCartList = createAsyncThunk(
  "payments/fetchCartList",
  async (_, thunkAPI?: any) => {
    try {
      const result = await DataProvider.getList(`purchases`);

      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const addToCart = createAsyncThunk(
  "payments/addToCart",
  async (planId: any, thunkAPI?: any) => {
    try {
      const result = await DataProvider.post(`purchases/${planId}`);
      thunkAPI.dispatch(fetchCartList());
      thunkAPI.dispatch(
        fetchPlanAdvancedSearch({
          isFavorite: false,
          pageNumber: 1,
          pageSize: 100,
          searchCriteria: {
            difficulty: null,
            subCategoryId: null,
            minPlanPrice: null,
            maxPlanPrice: null,
            projectCost: null,
            rate: null,
            orderType: null,
            lstRate: null,
          },
        })
      );
      if (result) {
        message.success("Item added to cart successfully");
        thunkAPI.dispatch(setShowCartModal(true));
      }
      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const removeFromCart = createAsyncThunk(
  "payments/removeFromCart",
  async (planId: any, thunkAPI?: any) => {
    try {
      const result = await DataProvider.delete(`purchases`, { id: planId });
      if (result) {
        message.success("Item removed from cart successfully");
      }
      thunkAPI.dispatch(fetchCartList());
      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const createPaymentLink = createAsyncThunk(
  "payments/createPaymentLink",
  async (_, thunkAPI?: any) => {
    try {
      const result = await DataProvider.post(
        `payments/create-payment-link?returnUrl=${process.env.REACT_APP_PAYMENT_RETURN_URL_PROD}`
      );
      if (result?.isTotalFree) {
        window.location.href = result.paymentLink;
      } else {
        const linkUrl = result.paymentLink;
        window.location.href = linkUrl;
      }

      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const cancelPayment = createAsyncThunk(
  "payments/cancelPayment",
  async (transactionId: string, thunkAPI?: any) => {
    try {
      const result = await DataProvider.getList(
        `payments/${transactionId}/cancel`
      );
      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const confirmPayment = createAsyncThunk(
  "payments/confirmPayment",
  async (transactionId: string, thunkAPI?: any) => {
    try {
      const result = await DataProvider.getList(
        `payments/${transactionId}/success`
      );

      thunkAPI.dispatch(setConfirmPayment(result));
      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);
