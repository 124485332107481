import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GARAGE_STATE_NAME } from "../models/states";
import { IGarageSlice } from "../../interfaces/IGarage";
import { fetchMaterialsList, fetchToolsList } from "../actions/GarageActions";

const initialState: IGarageSlice = {
  tools: {
    loading: false,
    error: null,
    data: {
      totalCount: 0,
      data: [],
    },
  },
  materials: {
    loading: false,
    error: null,
    data: {
      totalCount: 0,
      data: [],
    },
  },
};

export const GarageSlice = createSlice({
  name: GARAGE_STATE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //fetchGarageMaterials
      .addCase(fetchMaterialsList.pending, (state) => {
        state.materials.error = null;
        state.materials.loading = true;
      })
      .addCase(fetchMaterialsList.fulfilled, (state, action) => {
        state.materials.error = null;
        state.materials.loading = false;
        state.materials.data = action.payload;
      })
      .addCase(fetchMaterialsList.rejected, (state, action) => {
        state.materials.error = action.error.message;
        state.materials.loading = false;
      })

      //fetchGarageTools
      .addCase(fetchToolsList.pending, (state) => {
        state.tools.error = null;
        state.tools.loading = true;
      })
      .addCase(fetchToolsList.fulfilled, (state, action) => {
        state.tools.error = null;
        state.tools.loading = false;
        state.tools.data = action.payload;
      })
      .addCase(fetchToolsList.rejected, (state, action) => {
        state.tools.error = action.error.message;
        state.tools.loading = false;
      });
  },
});

export const {} = GarageSlice.actions;
export type GarageSlicePartialState = {
  [GarageSlice.name]: IGarageSlice;
  [key: string]: any;
};
