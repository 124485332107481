import { createSlice } from "@reduxjs/toolkit";

import { fetchPlansList } from "../actions/PlansActions";
import { PROJECTS_LIST_STATE_NAME } from "../models/states";
import { IPlansSlice } from "../../interfaces/IPlan";
import { IProjectsSlice } from "../../interfaces/IProject";
import { fetchProjectsList } from "../actions/ProjectsActions";

const initialState: IProjectsSlice = {
  selectedProjectId: null,
  projectsList: {
    loading: false,
    error: null,
    data: [],
  },
  projectDetail: null,
};

export const ProjectsSlice = createSlice({
  name: PROJECTS_LIST_STATE_NAME,
  initialState,
  reducers: {
    setPlanId: (state, action) => {
      state.selectedProjectId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchPlansList
      .addCase(fetchProjectsList.pending, (state) => {
        state.projectsList.error = null;
        state.projectsList.loading = true;
      })
      .addCase(fetchProjectsList.fulfilled, (state, action) => {
        state.projectsList.error = null;
        state.projectsList.loading = false;
        state.projectsList.data = action.payload;
      })
      .addCase(fetchProjectsList.rejected, (state, action) => {
        state.projectsList.error = action.error.message;
        state.projectsList.loading = false;
      });
  },
});

export type ProjectsSlicePartialState = {
  [ProjectsSlice.name]: IProjectsSlice;
  [key: string]: any;
};

export const { setPlanId } = ProjectsSlice.actions;
