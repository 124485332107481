import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  IShoppingListBulkRequest,
  IShoppingListRequest,
} from "../../interfaces/IShoppingList";
import { DataProvider } from "../../api/dataProvider";
import { message } from "antd";

export const addToShoppingList = createAsyncThunk(
  "shoppingList/addToShoppingList",
  async (data: IShoppingListRequest, thunkAPI?: any) => {
    try {
      const result = await DataProvider.post(`shopping-list`, data);

      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const addListToShoppingList = createAsyncThunk(
  "shoppingList/addListToShoppingList",
  async (data: IShoppingListBulkRequest, thunkAPI?: any) => {
    try {
      const result = await DataProvider.post(`shopping-list/bulk`, data);
      if (result) {
        message.success("Added to shopping list successfully");
      }

      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const fetchShoppingList = createAsyncThunk(
  "shoppingList/fetchShoppingList",
  async (searchedValue: string = "", thunkAPI?: any) => {
    try {
      const result = await DataProvider.getList(
        `shopping-list?search=${searchedValue}`
      );

      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const deleteAllSelectedShoppingListItem = createAsyncThunk(
  "shoppingList/deleteAllSelectedShoppingListItem",
  async (_, thunkAPI?: any) => {
    try {
      const result = await DataProvider.deleteAll(`shopping-list/truncate`);
      if (result) {
        message.success("Deleted all selected items successfully");
        thunkAPI.dispatch(fetchShoppingList());
      }
      return result;
    } catch (error: any) {
      console.log("error", error);

      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const deleteShoppingListItem = createAsyncThunk(
  "shoppingList/deleteShoppingListItem",
  async (id: string, thunkAPI?: any) => {
    try {
      const result = await DataProvider.delete(`shopping-list`, { id });
      if (result) {
        thunkAPI.dispatch(fetchShoppingList());
      }
      return result;
    } catch (error: any) {
      console.log("error", error);

      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const updateShoppingListItem = createAsyncThunk(
  "shoppingList/updateShoppingListItem",
  async (data: { status: number; id: string }, thunkAPI?: any) => {
    const { id, status } = data;
    try {
      const result = await DataProvider.update(`shopping-list/${id}`, {
        status,
      });
      if (result) {
        message.success("Updated successfully");
        thunkAPI.dispatch(fetchShoppingList());
      }
      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);
