import { createSlice } from "@reduxjs/toolkit";
import { IShoppingListSlice } from "../../interfaces/IShoppingList";
import { fetchShoppingList } from "../actions/ShoppingListActions";

const initialState: IShoppingListSlice = {
  data: {
    tools: [],
    materials: [],
    searchValue: null,
  },
  loading: false,
  error: "",
};

export const ShoppingListSlice = createSlice({
  name: "shoppingList",
  initialState,
  reducers: {
    setSearchValue: (state, action) => {
      state.data.searchValue = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchShoppingList.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(fetchShoppingList.fulfilled, (state, action) => {
        state.error = null;
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchShoppingList.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      });
  },
});

export type ShoppingListSlicePartialState = {
  [ShoppingListSlice.name]: IShoppingListSlice;
  [key: string]: any;
};

export const { setSearchValue } = ShoppingListSlice.actions;
