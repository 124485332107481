import { message } from "antd";
import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_API_URL?.replace(/^"|"$/g, "") || "";

export const api = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

const errorHandler = async (error: any) => {
  console.error("Error:", error);

  const statusCode = error.response?.status;

  if (statusCode) {
    switch (statusCode) {
      case 401:
        console.log("401 error", error);

        if (error?.config?.url === "/accounts/login") {
          message.error(error?.response?.data?.Message || "Unauthorized");
        } else {
          // Optional: Handle token refresh or redirect to login
          localStorage.removeItem("token");
          window.location.href = "/auth/sign-in";
        }

        return Promise.resolve();

      case 403:
        message.error("Forbidden: You do not have access to this resource.");
        return Promise.resolve();

      case 404:
        message.error("Resource not found.");
        return Promise.resolve();

      case 500:
        message.error("Internal Server Error. Please try again later.");
        return Promise.resolve();

      default:
        message.error(`Error: ${statusCode}`);
        return Promise.reject(error);
    }
  }

  if (error.code) {
    switch (error.code) {
      case "ERR_CANCELED":
        message.error("API Request Cancelled");
        return Promise.resolve();

      case "ERR_NETWORK":
        try {
          // Retry the request
          return await axios.request(error.config);
        } catch (refreshError) {
          console.error("Retrying failed:", refreshError);
          // Optional: Handle retry failure, e.g., force user login
          // authService.login();
          throw refreshError;
        }

      default:
        message.error(`Unknown Error: ${error.message}`);
        return Promise.reject(error);
    }
  }

  message.error("An unexpected error occurred.");
  return Promise.reject(error);
};

api.interceptors.response.use(undefined, async (error) => {
  return errorHandler(error);
});

// Add a request interceptor
api.interceptors.request.use(
  async (config) => {
    const accessToken: any = localStorage.getItem("token");

    if (accessToken) {
      config.headers["Authorization"] = "Bearer " + accessToken;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
