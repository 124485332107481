import { Button, Slider } from "antd";
import { table } from "console";

const LayoutTheme = {
  token: {
    colorPrimary: "#FF6E4F",
    // borderRadius: data.borderRadius,
  },
  components: {
    Collapse: {
      contentPadding: 0,
      headerBg: "#00323f",
    },
    Layout: {
      siderBg: "#00323f",
      triggerBg: "#00323f",
      headerBg: "#00323f",
    },

    Menu: {
      darkItemBg: "#00323f",
      darkSubMenuItemBg: "#00323f",
      darkItemSelectedBg: "rgba(0, 0, 0, 0.25)",
    },
    Modal: {
      wireframe: true,
    },
    Table: {
      headerBg: "#FF6E4F",
      headerColor: "#fff",
      headerSortHoverBg: "#FF6E4F",
      headerSortActiveBg: "#FFA68F",
    },
    Slider: {
      trackBg: "#FF6E4F",
      handleBg: "#FF6E4F",
      handleColor: "#FF6E4F",
      handleActiveOutlineColor: "#FF6E4F",
      handleActiveColor: "#FF6E4F",
      railHoverBg: "#FFA68F",
      trackHoverBg: "#FFA68F",
    },
  },
};

export default LayoutTheme;
