import { Spin } from "antd";
import React, { Suspense } from "react";

const AppRouter = React.lazy(() => import("./AppRoutes"));

const Loading = () => {
  const style: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    flexDirection: "column",
  };
  return (
    <div style={style}>
      <Spin size="large" />
      <p>Loading...</p>
    </div>
  );
};

function App() {
  return (
    <Suspense fallback={<Loading />}>
      <AppRouter />
    </Suspense>
  );
}

export default App;
