import { userLogin } from "../actions/AuthAction";
import { Middleware } from "@reduxjs/toolkit";
import { api } from "../../api/configs/axiosConfig";

const updateAxiosMiddleware: Middleware = (storeAPI) => (next) => (action) => {
  const result = next(action);

  if (userLogin.fulfilled.match(action)) {
    const token = action.payload.token;
    if (token) {
      api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
  }

  return result;
};

export default updateAxiosMiddleware;
