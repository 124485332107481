import { createAsyncThunk } from "@reduxjs/toolkit";
import { DataProvider } from "../../api/dataProvider";
import { message } from "antd";

export const fetchProjectsList = createAsyncThunk(
  "projects/fetchProjectsList",
  async (_, thunkAPI?: any) => {
    try {
      const result = await DataProvider.getList(`project`);

      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.message ||
          error.message
        }`
      );
      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const setProjectsRate = createAsyncThunk(
  "projects/setProjectsRate",
  async (data: any, thunkAPI?: any) => {
    const { planId, rate } = data;
    try {
      const result = await DataProvider.update(
        `plans/${planId}/rate?rateValue=${rate}`
      );
      if (result) {
        message.success("Rate updated successfully");
        thunkAPI.dispatch(fetchProjectsList());
      }

      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const updateProjectStatus = createAsyncThunk(
  "projects/updateProjectStatus",
  async (data: any, thunkAPI?: any) => {
    const { projectId, reqBody } = data;
    try {
      const result = await DataProvider.update(`project/${projectId}`, reqBody);

      if (result) {
        message.success("Project status updated successfully");
        thunkAPI.dispatch(fetchProjectsList());
      }
      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);

export const deleteProject = createAsyncThunk(
  "projects/deleteProject",
  async (projectId: string, thunkAPI?: any) => {
    try {
      const result = await DataProvider.delete(`project`, { id: projectId });

      if (result) {
        message.success("Project deleted successfully");
        thunkAPI.dispatch(fetchProjectsList());
      }
      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);
