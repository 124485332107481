import { createSlice } from "@reduxjs/toolkit";
import { IAuthSlice } from "../../interfaces/IAuth";
import { AUTH_STATE_NAME } from "../models/states";
import {
  fetchOnlineUserData,
  fetchUserData,
  fetchUserProfilePreview,
  userLogin,
  userLogout,
  userSignup,
} from "../actions/AuthAction";
import { api } from "../../api/configs/axiosConfig";

export const AuthSlice = createSlice({
  name: AUTH_STATE_NAME,
  initialState: {
    user: null,
    token: null,
    isAuthenticated: false,
    loading: false,
    error: null,
    selectedMenu: "1",
    onlineUser: {
      loading: false,
      data: {
        accountId: "",
        displayName: "",
        culture: "",
      },
      error: null,
    },
    userProfilePreview: {
      data: null,
      loading: false,
      error: null,
    },
  } as IAuthSlice,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;

      api.defaults.headers.common["Authorization"] = `Bearer ${action.payload}`;

      state.isAuthenticated = true;
    },
    setSelectedMenu: (state, action) => {
      state.selectedMenu = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // ************ sign in
      .addCase(userLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload.token;
        state.user = action.payload.username;
        if (action.payload.token) {
          state.isAuthenticated = true;
        }
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // *********** sign out
      .addCase(userLogout.fulfilled, (state) => {
        state.token = null;
        state.user = null;
        state.isAuthenticated = false;
      })
      .addCase(userLogout.rejected, (state, action) => {
        state.error = action.error.message;
      })

      // ************ get user data
      .addCase(fetchOnlineUserData.pending, (state) => {
        state.onlineUser.loading = true;
      })
      .addCase(fetchOnlineUserData.fulfilled, (state, action) => {
        state.onlineUser.loading = false;
        state.onlineUser.data = action.payload;
        state.isAuthenticated = true;
      })
      .addCase(fetchOnlineUserData.rejected, (state, action) => {
        state.onlineUser.loading = false;
        state.onlineUser.error = action.error.message;
      })

      // ************ fetch user data
      .addCase(fetchUserData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // ************ user profile preview
      .addCase(fetchUserProfilePreview.pending, (state) => {
        state.userProfilePreview.loading = true;
      })
      .addCase(fetchUserProfilePreview.fulfilled, (state, action) => {
        state.userProfilePreview.loading = false;
        state.userProfilePreview.data = action.payload;
      })
      .addCase(fetchUserProfilePreview.rejected, (state, action) => {
        state.userProfilePreview.loading = false;
        state.userProfilePreview.error = action.error.message;
      });
  },
});
export const { setToken, setSelectedMenu } = AuthSlice.actions;

export type AuthSlicePartialState = {
  [AuthSlice.name]: IAuthSlice;
  [key: string]: any;
};
