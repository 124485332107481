import { createBrowserHistory } from "history";
import { RouterState, createReduxHistoryContext } from "redux-first-history";
import { PlansSlice } from "./slices/PlansSlice";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { AuthSlice } from "./slices/AuthSlice";
import {
  ThunkDispatch,
  configureStore,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import { useDispatch } from "react-redux";
import { setupListeners } from "@reduxjs/toolkit/query";
import updateAxiosMiddleware from "./middleware/updateAxiosMiddleware";
import { ProjectsSlice } from "./slices/ProjectsSlice";
import { CategoriesSlice } from "./slices/CategoriesSlice";
import { PaymentSlice } from "./slices/PaymentSlice";
import { GarageSlice } from "./slices/GarageSlice";
import { ShoppingListSlice } from "./slices/ShoppingListSlice";

const browserHistory = createBrowserHistory();
const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: browserHistory as any,
    reduxTravelling: true,
    savePreviousLocations: 5,
  });

const reducers = combineReducers({
  router: routerReducer,
  [PlansSlice.name]: PlansSlice.reducer,
  [ProjectsSlice.name]: ProjectsSlice.reducer,
  [AuthSlice.name]: AuthSlice.reducer,
  [CategoriesSlice.name]: CategoriesSlice.reducer,
  [PaymentSlice.name]: PaymentSlice.reducer,
  [GarageSlice.name]: GarageSlice.reducer,
  [ShoppingListSlice.name]: ShoppingListSlice.reducer,
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["router", AuthSlice.name],
};
const persistedReducer = persistReducer(persistConfig, reducers);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(routerMiddleware, updateAxiosMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;
export const useAppDispatch = () => useDispatch<ThunkAppDispatch>();
export const currentRouteSelector = (state: RootState) =>
  state.router as RouterState;
export const history = createReduxHistory(store);
setupListeners(store.dispatch);
