import { createAsyncThunk } from "@reduxjs/toolkit";
import { DataProvider } from "../../api/dataProvider";
import { message } from "antd";

export const fetchCategoriesList = createAsyncThunk(
  "categories/fetchCategoriesList",
  async (_, thunkAPI?: any) => {
    try {
      const result = await DataProvider.getList(`categories`);

      return result;
    } catch (error: any) {
      message.error(
        `${
          error?.response?.data?.title ||
          error?.response?.data?.Message ||
          error.message
        }`
      );

      return thunkAPI.rejectWithValue(error?.response || error.message);
    }
  }
);
