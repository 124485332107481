import { createSlice } from "@reduxjs/toolkit";
import { ICategoriesSlice } from "../../interfaces/ICategory";
import { fetchCategoriesList } from "../actions/CategoriesAction";
import { CATEGORY_STATE_NAME } from "../models/states";

const initialState: ICategoriesSlice = {
  data: [],
  loading: false,
  error: null,
};

export const CategoriesSlice = createSlice({
  name: CATEGORY_STATE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategoriesList.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(fetchCategoriesList.fulfilled, (state, action) => {
        state.error = null;
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchCategoriesList.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      });
  },
});

export type CategoriesSlicePartialState = {
  [CategoriesSlice.name]: ICategoriesSlice;
  [key: string]: any;
};
