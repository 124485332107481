import { createSlice } from "@reduxjs/toolkit";
import { IPaymentSlice } from "../../interfaces/IPayment";
import { createPaymentLink, fetchCartList } from "../actions/PaymentActions";
import { PAYMENT_STATE_NAME } from "../models/states";

const initialState: IPaymentSlice = {
  transactionId: null,
  cart: {
    error: null,
    loading: false,
    data: {
      count: 0,
      totalPrice: 0,
      items: [],
    },
  },
  confirmPayment: {
    billingAddress: {
      name: "",
      address: "",
      phone: "",
      email: "",
    },
    shoppingListVm: [],
    totalPrice: 0,
  },
  showCartModal: false,
};

export const PaymentSlice = createSlice({
  name: PAYMENT_STATE_NAME,
  initialState,
  reducers: {
    resetPayment: (state) => {
      state = initialState;
    },
    setTransactionId: (state, action) => {
      state.transactionId = action.payload;
    },
    setConfirmPayment: (state, action) => {
      state.confirmPayment = action.payload;
    },
    setShowCartModal: (state, action) => {
      state.showCartModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Fetch Cart List
    builder.addCase(fetchCartList.pending, (state) => {
      state.cart.loading = true;
    });
    builder.addCase(fetchCartList.fulfilled, (state, action) => {
      state.cart.loading = false;
      state.cart.data = action.payload;
    });
    builder.addCase(fetchCartList.rejected, (state, action) => {
      state.cart.loading = false;
      state.cart.error = action.payload as string;
    });
  },
});

export type PaymentSlicePartialState = {
  [PaymentSlice.name]: IPaymentSlice;
  [key: string]: any;
};
export const {
  resetPayment,
  setTransactionId,
  setConfirmPayment,
  setShowCartModal,
} = PaymentSlice.actions;
